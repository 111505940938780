import { getHostUrl, isLocalEnvironment } from '@/environment';
import { getAccessTokenFixtureFromCookies } from '@/src/modules/auth/utils/auth.utils';
import { UseMETHODOptions } from '@/src/modules/common/hooks/useGET';

export type UsePOSTOptions = {
	isRawResponse?: boolean;
} & UseMETHODOptions;

export type UsePOST<TPayload, TData> = (
	payload?: TPayload | null,
) => Promise<TData | null | undefined>;

export const usePOST = <TPayload, TData>({
	endpoint,
	apiVersion = 2,
	isRawResponse,
	isFullEndpoint = false,
}: UsePOSTOptions): UsePOST<TPayload, TData> => {
	return async (payload?: TPayload | null) => {
		let headers: Record<string, string> = {
			'Content-Type': 'application/json;charset=utf-8',
		};
		let credentials: RequestCredentials | undefined = 'include';

		if (isLocalEnvironment) {
			const accessToken = getAccessTokenFixtureFromCookies();
			headers = { ...headers, authorization: `Bearer ${accessToken}` };
			credentials = undefined;
		}

		const url = endpoint
			? isFullEndpoint
				? typeof endpoint === 'function'
					? endpoint()
					: endpoint
				: typeof endpoint === 'function'
					? `${getHostUrl()}api/v${apiVersion}${endpoint()}`
					: `${getHostUrl()}api/v${apiVersion}${endpoint}`
			: '';

		const response = await fetch(url, {
			method: 'POST',
			headers,
			credentials,
			body: payload ? JSON.stringify(payload) : null,
		});

		return isRawResponse
			? ((await response.text()) as TData)
			: ((await response.json()) as { data: TData }).data;
	};
};
