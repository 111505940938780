import { workspacesApiPath } from '@/src/modules/workspaces/routes/workspaces.api.routes';

export const projectEventsRoutes = {
	eventsApiPath: `/events`,
	workspacesEventsApiPath: (workspaceId: string) => `${workspacesApiPath}/${workspaceId}/events`,
	projectsApiPath: (workspaceId: string) => `${workspacesApiPath}/${workspaceId}/projects`,
	projectApiPath: (projectId: string) => `/projects/${projectId}`,
	seasonsApiPath: (workspaceId: string) => `${workspacesApiPath}/${workspaceId}/seasons`,
	seasonApiPath: (seasonId: number) => `/seasons/${seasonId}`,
	eventApiPath: (eventId: number) => `${projectEventsRoutes.eventsApiPath}/${eventId}`,
	eventArchiveApiPath: (eventId: number) =>
		`${projectEventsRoutes.eventApiPath(eventId)}/archive`,
};
